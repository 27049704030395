<template>
  <div class="contents liveInfo" style="position: relative; height: calc(100vh - 100px);">

    <div class="box one filter" style="display: flex;justify-content: flex-start;align-items: center;margin-bottom: 20px;">
      <div class="flex">
        <p class="bold">{{ $t("search-keyword-title") }}</p>
        <select v-model="keywordType">
          <option value="all">{{ $t("search-keyword-all") }}</option>
            <option value="title">{{ $t("livemap-data-way-name") }}</option>
            <option value="userDepartmentName">{{ $t("livemap-data-way-dept") }}</option>
            <option value="userName">{{ $t("livemap-data-way-operator") }}</option>
            <option value="deviceModelName">{{ $t("livemap-data-way-drone") }}</option>
        </select>
        <input
          type="text"
          v-model="keyword"
          @keydown.enter="search()"
          />
      </div>
      <div class="flexC">
        <p class="bold">{{ $t("search-regdate-title") }}</p>
        <Datepicker
            v-model="startYmd"
            :language="ko"
            :format="customFormatter"
            placeholder="YYYY-MM-DD"
        ></Datepicker>
        <span>~</span>
        <Datepicker
            v-model="endYmd"
            :language="ko"
            :format="customFormatter"
            placeholder="YYYY-MM-DD"
        ></Datepicker>
      </div>
      <div class="buttonWrap" style="display: flex; margin-top: 0;">
          <button class="point medium" @click="search()" style="width: 120px; margin-left: 20px;background:#0080FF;border-color:#0080FF;border-radius:5px;font-weight:400;">
            {{ $t("btn-searching") }}
          </button>
          <button class="medium margin6" @click="reset" style="width: 120px;border-color:#ddd;color:#969696; font-weight:400;border-radius:5px;">{{ $t("btn-reset") }}</button>
        </div>
    </div>

    

    <div class="box" style="width: 60%;margin-right: 20px;margin-bottom:0px;">
      <div class="flex">
        <ul class="tabSecond2 flex">
          <li :class="{ active: visible == showType[0] ? true : false }">
            <a class="point medium" @click="handleTab(showType[0])">
              {{ $t("live-map-tab-optics-img")}}
            </a>
          </li>
          <li :class="{ active: visible == showType[1] ? true : false }">
            <a class="medium" @click="handleTab(showType[1])">
              {{ $t("live-map-tab-thermal-img")}}
            </a>
          </li>
        </ul>
      </div>
      <p style="color: #606060; font-size: 1.2rem; line-height: 3rem;margin-bottom:0px;padding-left:5px;">{{ $t("data-total") }} <span style="color: #0080FF; font-size:1.2rem;">{{total}}</span> {{ $t("overall-data-event-ea") }}</p>
      <div class="flex">
        <LiveMapOpticsList ref="lmov"  v-if="visible == showType[0]" :eventId = "eventId"/>
        <LiveMapThermalList ref="lmtv"  v-if="visible == showType[1]" :eventId = "eventId"/>
      </div>
    </div>

  </div>

</template>
<style>
div.liveMapMark {
  position: absolute;
  width: 60px;
  height: 18px;
  right: 12px;
  top: 9px;
  padding: 2px 0;
  text-align: center;
  background-color: #ffc002;
  border-radius: 9px;
}
span.markTxt {
  width: 27px;
  height: 15px;
  font-family: Noto Sans KR;
  font-size: 1rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #000;
}
.search-from{width: 22%;background: #fff;border-radius: 5px;padding: 15px;margin-bottom: 15px;}
.search-from > h2{font-size: 1.4rem;font-weight: bold;border-bottom: 1px solid #ddd;padding-bottom: 5px;margin-bottom: 5px;}

.search-from-input{display: flex;flex-direction: column;width: 100%;margin-bottom: 5px;}
.search-from-input > h4{font-size: 1.2rem;font-weight: 400;color: #969696;margin-bottom: 5px;}
.search-from-input > .search-input{display: flex;margin-bottom: 5px;}
.search-from-input > .search-input > select{width: 30%;border-radius: 5px;margin-right: 5px;}
.search-from-input > .search-input > input{width: 69%;border-radius: 5px;margin-bottom: 0px;}
.search-from-input > .vdp-datepicker{width: 100%;}
.search-from-input > .vdp-datepicker input{width: 100%;border-radius: 5px;}
.search-from-btn{display: flex;flex-direction: row;width: 100%;}
</style>
<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import {ko} from "vuejs-datepicker/dist/locale";
import LiveMapOpticsList from "./optics/LiveMapOpticsList";
import LiveMapThermalList from "./thermal/LiveMapThermalList";

export default {
  components: { LiveMapOpticsList, LiveMapThermalList, Datepicker },
  props: {
    eventId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      index: 0,
      moment: moment,
      visible: "Optics",
      showType: ["Optics", "Thermal"],

      ko: ko,
      startYmd: null,
      endYmd: null,
      currentPage: 0,
      size: 15,
      keywordType: "all",
      keyword: "",
      total: 0,

      waypointId : null,
      refId: null,
      contentsId : null,

    };
  },
  created() {
    this.$store.dispatch("store/SET_NAVBAR", { nav: 9, sub: 5 });
  },
  mounted() {
    const visibleTabInfoFromStorage = localStorage.getItem("liveMap_visibleTab");
    if(this.showType.includes(visibleTabInfoFromStorage)){
      this.visible = visibleTabInfoFromStorage;
    }
  },
  methods: {
    handleTab(id) {
      this.visible = id;
      this.currentPage = 0;
      localStorage.setItem("liveMap_visibleTab", this.visible);
      localStorage.removeItem("liveMap_optics_searchData");
      localStorage.removeItem("liveMap_thermal_searchData");
      this.reset();
    },
    customFormatter(date) {
      return moment(date).format("YYYY년 MM월 DD일");
    },
    search(){
      var ref = null;
      if(this.visible === this.showType[0]){
        ref = this.$refs.lmov;
      } else if(this.visible === this.showType[1]){
        ref = this.$refs.lmtv;
      }
      ref.search(this.keyword, this.keywordType, 0, this.startYmd, this.endYmd);
    },
    reset(){
      this.keyword = "";
      this.keywordType = "all";
      this.startYmd = null;
      this.endYmd = null;
    },
  }
};
</script>
